// Future optimization notes
// - rendering jitter - https://threejs.org/examples/webgl_worker_offscreencanvas.html
// - send deltas
// - send compressed deltas
// - send compressed deltas of each type of particle
// - send only input deltas and have client compute the rest (deterministic)
import {decode, encode} from "@thi.ng/rle-pack";
import * as THREE from 'three';

import PartySocket from "partysocket";
import {
  CellType,
  type CellUpdate,
  type ClickActionMessage,
  type DragMouseActionMessage,
  drawLine,
  EMPTY,
  GRID_HEIGHT,
  GRID_WIDTH,
  MouseActionType,
  OBSTACLE, PREDICT_MULTIPLE,
  SAND,
  SERVER_GRID_UPDATE_FREQUENCY,
  updateGrid,
  gridFlatTo2D, initGrid, WATER,
} from "./sharedSim";
// @ts-ignore
import Stats from 'stats.js'
import GUI from 'lil-gui';
import {TextureLoader} from "three";

const scene = new THREE.Scene();

const aspectRatio = window.innerWidth / window.innerHeight;
const cameraViewHeight = 20;
const cameraViewWidth = cameraViewHeight * aspectRatio;

interface Point2D {
  x: number;
  y: number;
}

let lastPosition: Point2D | null = null;
let lastPositionHover: Point2D | null = null;

const camera = new THREE.OrthographicCamera(
  -cameraViewWidth / 2,
  cameraViewWidth / 2,
  cameraViewHeight / 2,
  -cameraViewHeight / 2,
  0,
  100
);
camera.position.z = 30;  // Adjust this to move the camera further out if required

const stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
// hide
document.body.appendChild(stats.dom);
stats.dom.style.display = 'none';

const gui = new GUI({
  closeFolders: true,
  title: 'Stats',
  // start collapsed
  open: false,

});
const guiControls = {
  overwrite: false,
};
const brushSettings = {
  brushSize: 6
};

gui.hide();



gui.add(brushSettings, 'brushSize', 1, 50).name('Brush Size').step(1);

const lilStats = {
  frameCount: 0,
  maxMessageDelta: 0,
  averageMessageDelta: 0,
  messageDelta: 0,
};
gui.add(lilStats, 'frameCount').name('Frame Counter').listen();
gui.add(lilStats, 'messageDelta').name('Last Message Delay').listen();
gui.add(lilStats, 'maxMessageDelta').name('Max Message Delay').listen();
gui.add(lilStats, 'averageMessageDelta').name('Avg Message Delay').listen().decimals(0);
gui.add(guiControls, 'overwrite').name('Overwrite');

let localGridModel = initGrid();


let currentTool = SAND; // Default tool
const tools = {
  [EMPTY]: 'Air',
  [OBSTACLE]: 'Obstacle',
  [SAND]: 'Sand',
  [WATER]: 'Water',
};
const toolDisplay = document.getElementById('currentTool');
updateToolDisplay();

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.addEventListener('touchmove', function (e) {
  e.preventDefault();
}, {passive: false});
window.addEventListener('resize', function () {
  document.documentElement.style.height = window.innerHeight + 'px';
});

renderer.domElement.addEventListener('wheel', (event) => {
  if (event.shiftKey) {
    // Scroll up to increase size, down to decrease size
    if (event.deltaY < 0) {
      // Increase brush size, but not more than the maximum set in the GUI
      brushSettings.brushSize = Math.min(brushSettings.brushSize + 1, 50);
    } else {
      // Decrease brush size, but not less than the minimum
      brushSettings.brushSize = Math.max(brushSettings.brushSize - 1, 1);
    }

    // Update the GUI to reflect the new value
    // gui.updateDisplay();
    // event.preventDefault();
  } else {
      // Existing zoom logic
    // Set the zoom speed
    const zoomSpeed = 0.1;

    // Calculate the new zoom level
    let newZoom = camera.zoom;
    if (event.deltaY < 0) {
      newZoom += zoomSpeed * (2 / newZoom) ^ 1.1;
    } else {
      newZoom -= zoomSpeed * (2 / newZoom) ^ 1.1;
    }

    // Update the camera zoom and update the projection matrix
    camera.zoom = Math.max(0.95, Math.min(50, newZoom));
    camera.updateProjectionMatrix();

    // Update the position of the camera to zoom towards the mouse position
    const mousePos = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    const vector = new THREE.Vector3(mousePos.x, mousePos.y, 0.5);
    vector.unproject(camera);

    const dir = vector.sub(camera.position).normalize();
    const distance = -camera.position.z / dir.z;

    const newPos = (event.deltaY < 0)
      ? camera.position.clone().add(dir.multiplyScalar(distance))
      : camera.position.clone(); // no change in position when zooming out

    // Constrain the camera's position to the bounds of the scene
    const halfWidth = canvasWidth / 2;
    const halfHeight = canvasHeight / 2;

    newPos.x = Math.max(-halfWidth, Math.min(halfWidth, newPos.x));
    newPos.y = Math.max(-halfHeight, Math.min(halfHeight, newPos.y));

    camera.position.lerp(newPos, 0.2);
  }
}, {passive: true});


window.addEventListener('resize', () => {
  const aspectRatio = window.innerWidth / window.innerHeight;
  const cameraViewHeight = 20; // This can be your choice of the height of the camera's view
  const cameraViewWidth = cameraViewHeight * aspectRatio;

  camera.left = -cameraViewWidth / 2;
  camera.right = cameraViewWidth / 2;
  camera.top = cameraViewHeight / 2;
  camera.bottom = -cameraViewHeight / 2;

  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});

renderer.domElement.style.zIndex = "-11";
renderer.domElement.style.position = "absolute";
renderer.domElement.style.imageRendering = "pixelated";

// @ts-ignore
document.querySelector('#gameCanvas').appendChild(renderer.domElement);
// document.body.appendChild();

// Connect to socket server

const socket = new PartySocket({
  // @ts-ignore
  host: PARTYKIT_HOST, // automatically defined
  room: "my-new-room",
});


let debugLastUpdateTime = 0;

function updateLastMessageDebugTime() {
  if (debugLastUpdateTime > 0) {
    const delta = +Date.now() - debugLastUpdateTime;
    lilStats.messageDelta = delta;
    lilStats.averageMessageDelta = (lilStats.averageMessageDelta * lilStats.frameCount + delta) / (lilStats.frameCount + 1);
    lilStats.maxMessageDelta = Math.max(lilStats.maxMessageDelta, delta);
  }
  debugLastUpdateTime = +Date.now();
}

socket.onmessage = async function(event) {
  updateLastMessageDebugTime();
  const buffer = await event.data.arrayBuffer();
  const array = new Uint8Array(buffer);
  const flatGridWithCount = decode(array);
  const frameCountDecoded = (flatGridWithCount[flatGridWithCount.length - 4] << 24) |
      (flatGridWithCount[flatGridWithCount.length - 3] << 16) |
      (flatGridWithCount[flatGridWithCount.length - 2] << 8) |
      flatGridWithCount[flatGridWithCount.length - 1];
  // console.log(`Received frame ${frameCountDecoded}`);
  latestServerFrame = frameCountDecoded;
  clientFrame = latestServerFrame;
  // @ts-ignore
  localGridModel = flatGridWithCount.slice(0, flatGridWithCount.length - 4);
};

// @ts-ignore
document.getElementById('currentTool').addEventListener('click', () => {
  currentTool = (currentTool + 1) % ALL_TYPES.length;
  updateToolDisplay();
});

document.addEventListener('keydown', (event) => {
  switch(event.key) {
    case '1':
      currentTool = SAND;
      break;
    case '2':
      currentTool = EMPTY;
      break;
    case '3':
      currentTool = OBSTACLE;
      break;
    case '4':
      currentTool = WATER;
      break;
    case '0':
      if (gui._hidden) {
        gui.show();
        stats.dom.style.display = 'initial';
      } else {
        stats.dom.style.display = 'none';
        gui.hide();
      }
      console.log("Showing")
      break;
    default: return;
  }
  // updateToolDisplay();
  event.preventDefault(); // prevent the default action
});

function updateToolDisplay() {
  lastPosition = null;
  // @ts-ignore
  toolDisplay.textContent = `Current Tool: ${tools[currentTool]}`;
}

window.addEventListener('blur', function() {
  lastPosition = null;
});

// renderer.domElement.addEventListener('click', createSand, { passive: true });

let dragging = false;

function sendMouseClick(gridPos: { gridX: number; gridY: number }) {
  const mouseAction: ClickActionMessage = {
    actionType: MouseActionType.CLICK,
    x: gridPos.gridX,
    y: gridPos.gridY,
    cellType: currentTool,
    frame: clientFrame,
    brushSize: brushSettings.brushSize
  };
  const mouseActionSerialized = JSON.stringify(mouseAction);
  socket.send(mouseActionSerialized);
  lastPosition = { x: gridPos.gridX, y: gridPos.gridY };
}

function handleMouseDrag(gridPos: { gridX: number; gridY: number }) {
  if (!lastPosition) {
    // console.error("No last position!");

    return;
  }

  const mouseAction: DragMouseActionMessage = {
    actionType: MouseActionType.DRAG,
    x: gridPos.gridX,
    y: gridPos.gridY,
    lastX: lastPosition.x,
    lastY: lastPosition.y,
    cellType: currentTool,
    frame: clientFrame,
    brushSize: brushSettings.brushSize
  };
  lastPosition = { x: gridPos.gridX, y: gridPos.gridY };
  const mouseActionSerialized = JSON.stringify(mouseAction);
  socket.send(mouseActionSerialized);
  drawLine(localGridModel, mouseAction.x, mouseAction.y, mouseAction.lastX, mouseAction.lastY, mouseAction.brushSize, mouseAction.cellType);
}

renderer.domElement.addEventListener('mousedown', (event: MouseEvent) => {
  dragging = true;
  const gridPos = viewportPosToGrid(event.clientX, event.clientY);
  sendMouseClick(gridPos);

}, { passive: true });


renderer.domElement.addEventListener('mousemove', (event:MouseEvent) => {
  const {gridX, gridY} = viewportPosToGrid(event.clientX, event.clientY);
  lastPositionHover = { x: gridX, y: gridY };

  if (dragging) {
    handleMouseDrag(viewportPosToGrid(event.clientX, event.clientY));
    console.log("Dragging...");
  } else {
    // Visualize the brush shape on hover
    // visualize others' here eventually maybe
  }

}, {passive: true});

renderer.domElement.addEventListener('mouseup', () => {
  endDragging();
}, {passive: true});

renderer.domElement.addEventListener('touchstart', (event:TouchEvent) => {
  dragging = true;
  const touch = event.touches[0];
  sendMouseClick(viewportPosToGrid(touch.clientX, touch.clientY));
}, {passive: true});

renderer.domElement.addEventListener('touchmove', (event:TouchEvent) => {
  if (dragging) {
    const touch = event.touches[0];
    handleMouseDrag(viewportPosToGrid(touch.clientX, touch.clientY));
  }
}, {passive: true});

function endDragging() {
  // console.log("Ending Dragging...");
  dragging = false;
  lastPosition = null;
}

renderer.domElement.addEventListener('touchend', () => {
  endDragging();
}, {passive: true});

function viewportPosToGrid(viewportX: number, viewportY: number) {
  const rect = renderer.domElement.getBoundingClientRect();

  // Normalized device coordinates
  const x = ((viewportX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
  const y = -((viewportY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;

  const mousePos = new THREE.Vector3(x, y, 0.5);
  mousePos.unproject(camera);

  // Convert from world coordinates to grid coordinates
  const gridX = Math.floor((mousePos.x + canvasWidth / 2) / CELL_WIDTH);
  const gridY = Math.floor((canvasHeight / 2 - mousePos.y) / CELL_HEIGHT);
  return {gridX, gridY};
}

let drawingsThisFrame = 0;

let pendingUpdates: PendingUpdate[] = [];
type PendingUpdate = {
    update: CellUpdate,
    clientFrame: number,
    state: PendingUpdateState
}
enum PendingUpdateState {
    PENDING,
    DISPATCHED,
    REJECTED
}

let clientFrame = -1;  // Track the client's frame number

function placeDot(x:number, y:number) {
  // Allow overwrite for walls
  if (!guiControls.overwrite && localGridModel[y * GRID_WIDTH + x] !== EMPTY && currentTool !== EMPTY && currentTool !== OBSTACLE) {
    return;
  }

  if (localGridModel[y * GRID_WIDTH + x] === currentTool) {
    return;
  }

  ++drawingsThisFrame;
  const update = {
    x: x,
    y: y,
    cellType: currentTool
  } as CellUpdate;
  createLocalUpdate(update, clientFrame);
  pendingUpdates.push({
    update: update,
    clientFrame: clientFrame,
    state: PendingUpdateState.PENDING
  });
}


const localUpdates: {
    update: CellUpdate,
    clientFrame: number
}[] = [];

function createLocalUpdate(update: CellUpdate, clientFrame: number) {
  localUpdates.push({
    update: update,
    clientFrame: clientFrame
  });
  localGridModel[update.y * GRID_WIDTH + update.x] = update.cellType;
}

// This function will draw a circle around the given (cx, cy) with the given radius.
function drawCircle(cx:number, cy:number, radius:number) {
  if (radius === 1) {
    placeDot(cx, cy);
    return;
  }
  for (let y = Math.max(0, cy - radius); y <= Math.min(GRID_HEIGHT - 1, cy + radius); y++) {
    for (let x = Math.max(0, cx - radius); x <= Math.min(GRID_WIDTH - 1, cx + radius); x++) {
      const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
      if (distance <= radius) {
        placeDot(x, y);
      }
    }
  }
}


renderer.domElement.addEventListener('mouseup', () => {
  dragging = false;
  lastPosition = null;  // Reset the last position when the mouse is released
}, {passive: true});


const canvasWidth = 20;
const canvasHeight = 20;

const CELL_WIDTH = canvasWidth / GRID_WIDTH;
const CELL_HEIGHT = canvasHeight / GRID_HEIGHT;

// Initialize the dataTexture with a correctly sized array.
const initialData = new Uint8Array(GRID_WIDTH * GRID_HEIGHT * 3);
const dataTexture = new THREE.DataTexture(initialData, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
dataTexture.needsUpdate = true;

const material = new THREE.MeshBasicMaterial({ map: dataTexture });
const planeGeometry = new THREE.PlaneGeometry(canvasWidth, canvasHeight);
const planeMesh = new THREE.Mesh(planeGeometry, material);
planeMesh.scale.y = -1;
scene.add(planeMesh);

let latestServerFrame = -1;

function updateData(update: CellUpdate, data: Uint8ClampedArray) {
  const {x, y} = update;
  const idx = (y * GRID_WIDTH + x) * 4;
  const color: number = getCellColor(update.cellType);
  data[idx] = (color >> 16) & 255;
  data[idx + 1] = (color >> 8) & 255;
  data[idx + 2] = color & 255;
  data[idx + 3] = 255; // Alpha, you can adjust this if needed
}
function updateTextureFromGrid() {
  const data = new Uint8ClampedArray(GRID_WIDTH * GRID_HEIGHT * 4);
  for (let y = 0; y < GRID_HEIGHT; y++) {
    for (let x = 0; x < GRID_WIDTH; x++) {
      updateData({x, y, cellType: localGridModel[y * GRID_WIDTH + x]}, data);
    }
  }

  for (let i = pendingUpdates.length - 1; i >= 0; i--) {
    const { update, clientFrame: expiryFrame , state } = pendingUpdates[i];
    // console.log(`Pending update ${i} with state ${state}`);
    // console.log(`Latest client ${expiryFrame} server frame: ${latestServerFrame}`);
    if (expiryFrame + PREDICT_MULTIPLE * 4 < latestServerFrame) {
      pendingUpdates.splice(i, 1); // expired - remove from list
      continue;
    }
    updateData(update, data);
  }

  // Render brush preview
  if (lastPositionHover) {
    // update when drawing with mouse

    const {x, y} = lastPositionHover;
    if (brushSettings.brushSize === 1) {
      updateData({x, y, cellType: currentTool}, data);
    } else {
      for (let yDots = Math.max(0, y - brushSettings.brushSize); yDots <= Math.min(GRID_HEIGHT - 1, y + brushSettings.brushSize); yDots++) {
        for (let xDots = Math.max(0, x - brushSettings.brushSize); xDots <= Math.min(GRID_WIDTH - 1, x + brushSettings.brushSize); xDots++) {
          const distance = Math.sqrt((xDots - x) ** 2 + (yDots - y) ** 2);
          if (distance <= brushSettings.brushSize) {
            updateData({x: xDots, y: yDots, cellType: currentTool}, data);
          }
        }
      }
    }
  }

  // Convert data to texture and update
  //   material.map = new THREE.DataTexture(data, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
  // material.map
  //   material.needsUpdate = true;
  dataTexture.image = new ImageData(data, GRID_WIDTH, GRID_HEIGHT);
  dataTexture.needsUpdate = true;
}
function getCellColor(cellType:CellType):number {
  switch(cellType) {
    case CellType.EMPTY: return 0xAAAAAA;
    case CellType.SAND: return 0xFFFF00;
    case CellType.WALL: return 0x000000;
    case CellType.WATER: return 0x0000FF;
  }
  return 0xAAAAAA;
}


function renderLoop() {
  stats.begin();
  // console.log("DTF" + drawingsThisFrame);
  drawingsThisFrame = 0;
  lilStats.frameCount++;
  clientFrame++;  // Increment client frame number each time we render

  updateTextureFromGrid();

  renderer.render(scene, camera);
  stats.end();
  requestAnimationFrame(renderLoop);
}

renderLoop();


function newEmptyGrid() {
  return new Array(GRID_HEIGHT).fill(0).map(() => new Array(GRID_WIDTH).fill(-1));
}

function dispatchDrawChanges() {
  if (pendingUpdates.length > 0) {
    // Construct a grid with -1 for each cell that has NOT been updated
    const grid = newEmptyGrid();
    // console.log(`Dispatching ${pendingUpdates.length} updates`)
    for (const update of pendingUpdates) {
      if (update.state === PendingUpdateState.DISPATCHED) {
        continue;
      }
      const {x, y} = update.update;
      update.state = PendingUpdateState.DISPATCHED;
      grid[y][x] = update.update.cellType;
    }
    let input = flattenGrid(grid);
    let inputBuffer = new Uint8Array(input);
    // If we want to interleave the step server-side, pass here
    // let inputBuffer = new Uint8Array(input.concat(gridStep));
    let compressedGrid = encode(inputBuffer, inputBuffer.length);

    if (compressedGrid.length < 50000) {
      socket.send(compressedGrid);
    } else {
      console.error("Message too large to send!");
    }
  }
  setTimeout(dispatchDrawChanges, 1000 / (SERVER_GRID_UPDATE_FREQUENCY));
}
dispatchDrawChanges();

function numberToBytes(number) {
  // you can use constant number of bytes by using 8 or 4
  const len = Math.ceil(Math.log2(number) / 8);
  const byteArray = new Uint8Array(len);

  for (let index = 0; index < byteArray.length; index++) {
    const byte = number & 0xff;
    byteArray[index] = byte;
    number = (number - byte) / 256;
  }

  return byteArray;
}

function bytesToNumber(byteArray) {
  let result = 0;
  for (let i = byteArray.length - 1; i >= 0; i--) {
    result = (result * 256) + byteArray[i];
  }

  return result;
}


function updateLoop() {
  // Always optimistically simulate the next state
  // dispatchDrawChanges(getGridStep());
  // localGridModel = updateGrid(localGridModel);

  // Actually let's only simulate the next state if we have no pending updates
    if (pendingUpdates.length === 0) {
      localGridModel = updateGrid(localGridModel);
    }

  // Set a timeout for the next update
  setTimeout(updateLoop, 1000 / (SERVER_GRID_UPDATE_FREQUENCY * PREDICT_MULTIPLE));
}

async function initUpdateAndStart() {
  updateLoop();
}

initUpdateAndStart();