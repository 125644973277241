function logMessage(message) {
  console.log(message);
}


export const GRID_WIDTH = 512;
export const GRID_HEIGHT = 512;

export function gridFlatTo2D(flatGrid: Grid1D, width: number = GRID_WIDTH, height: number = GRID_HEIGHT) {
  // const grid = [];
  // for (let y = 0; y < height; y++) {
  //     grid.push(flatGrid.slice(y * width, (y + 1) * width));
  // }
  // Do imperative version for performance
  const grid = new Array(height);
  for (let y = 0; y < height; y++) {
    grid[y] = new Array(width);
    for (let x = 0; x < width; x++) {
      grid[y][x] = flatGrid[y * width + x];
    }
  }
  return grid;
}

export enum CellType {
  EMPTY = 0,
  WALL = 1,
  SAND = 2,
  WATER = 3,
  NIL = 255,
}
export const MAX_CELL_TYPE_KNOWN = 3;

export const EMPTY = CellType.EMPTY;
export const SAND = CellType.SAND;
export const OBSTACLE = CellType.WALL;
export const WATER = CellType.WATER;

export type Grid1D = Uint8Array;

export function initGrid(): Grid1D {
  const grid = new Uint8Array(GRID_WIDTH * GRID_HEIGHT);
  // grid.fill(SAND);

  for (let x = 0; x < GRID_WIDTH; x++) {
    grid[(GRID_HEIGHT - 1) * GRID_WIDTH + x] = OBSTACLE;
  }

  for (let x = 1; x < GRID_WIDTH; x++) {
    grid[Math.floor(GRID_HEIGHT / 2) * GRID_WIDTH + x] = SAND;
    grid[(Math.floor(GRID_HEIGHT / 2) + 1) * GRID_WIDTH + x] = SAND;
  }

  for (let x = 0; x < GRID_WIDTH; x++) {
    grid[(Math.floor(GRID_HEIGHT / 2) - 1) * GRID_WIDTH + x] = OBSTACLE;
  }

  return grid;
}

export interface CellUpdate {
  x: number;
  y: number;
  cellType: CellType;
}

interface BatchUpdate {
  updates: CellUpdate[];
  frame: number;
}


export enum ClientToServerMessageType {
  BATCH_UPDATE = "batchUpdate",
}

export interface ClientToServerMessage {
  type: ClientToServerMessageType;
}

export interface BatchUpdateMessage extends ClientToServerMessage {
  type: ClientToServerMessageType.BATCH_UPDATE;
  data: BatchUpdate;
}

export type Grid2D = CellType[][]; // y then x
export type Grid1D = CellType[]; // modulo GRID_WIDTH


export function gridIsFull(grid: Grid1D) {
  return !grid.includes(EMPTY);
}

let localGridStep = 0;

type ElementBehavior = {
  id: number;
  onStep: (index: number, inputGrid: Grid1D, newGrid: Uint8Array) => void;
};

const ELEMENT_BEHAVIORS: Record<number, ElementBehavior> = {
  [SAND]: {
    id: SAND,
    onStep: (index, newGrid, inputGrid) => {
      const x = index % GRID_WIDTH;
      const y = Math.floor(index / GRID_WIDTH);
      if (y + 1 < GRID_HEIGHT) {
        const belowIndex = (y + 1) * GRID_WIDTH + x;
        const canMoveDown = newGrid[belowIndex] === EMPTY;
        const canSwapWithWater = newGrid[belowIndex] === WATER;
        const canMoveLeft = x > 0 && newGrid[belowIndex - 1] === EMPTY && newGrid[index - 1] !== OBSTACLE;
        const canMoveRight = x < GRID_WIDTH - 1 && newGrid[belowIndex + 1] === EMPTY && newGrid[index + 1] !== OBSTACLE;

        if (canMoveDown) {
          newGrid[belowIndex] = SAND;
          newGrid[index] = EMPTY;
        } else if (canSwapWithWater) {
          newGrid[belowIndex] = SAND;
          newGrid[index] = WATER;
        } else if (canMoveLeft) {
          newGrid[belowIndex - 1] = SAND;
          newGrid[index] = EMPTY;
        } else if (canMoveRight) {
          newGrid[belowIndex + 1] = SAND;
          newGrid[index] = EMPTY;
        }
      }
    },
  },
  [WATER]: {
    id: WATER,
    onStep: (index, newGrid, inputGrid) => {
      const x = index % GRID_WIDTH;
      const y = Math.floor(index / GRID_WIDTH);
      const canMoveDown = y + 1 < GRID_HEIGHT && newGrid[(y + 1) * GRID_WIDTH + x] === EMPTY;
      const canMoveDownLeft = y + 1 < GRID_HEIGHT && x > 0 && newGrid[(y + 1) * GRID_WIDTH + (x - 1)] === EMPTY && newGrid[y * GRID_WIDTH + (x - 1)] !== OBSTACLE;
      const canMoveDownRight = y + 1 < GRID_HEIGHT && x < GRID_WIDTH - 1 && newGrid[(y + 1) * GRID_WIDTH + (x + 1)] === EMPTY && newGrid[y * GRID_WIDTH + (x + 1)] !== OBSTACLE;
      const canMoveLeft = x > 0 && newGrid[y * GRID_WIDTH + (x - 1)] === EMPTY;
      const canMoveRight = x < GRID_WIDTH - 1 && newGrid[y * GRID_WIDTH + (x + 1)] === EMPTY;

      if (canMoveDown) {
        newGrid[(y + 1) * GRID_WIDTH + x] = WATER;
        newGrid[index] = EMPTY;
      } else if (canMoveDownLeft) {
        newGrid[(y + 1) * GRID_WIDTH + (x - 1)] = WATER;
        newGrid[index] = EMPTY;
      } else if (canMoveDownRight) {
        newGrid[(y + 1) * GRID_WIDTH + (x + 1)] = WATER;
        newGrid[index] = EMPTY;
      } else if (canMoveLeft) {
        newGrid[y * GRID_WIDTH + (x - 1)] = WATER;
        newGrid[index] = EMPTY;
      } else if (canMoveRight) {
        newGrid[y * GRID_WIDTH + (x + 1)] = WATER;
        newGrid[index] = EMPTY;
      }
    },
  },
};

export function updateGrid(inputGrid: Grid1D): Grid1D {
  localGridStep++;
  const newGrid = new Uint8Array(inputGrid);
  const stepConfig = getStepConfig(localGridStep);

  for (let y = stepConfig.yStart; y !== stepConfig.yEnd && y >= 0 && y < GRID_HEIGHT; y += stepConfig.yStep) {
    for (let x = stepConfig.xStart; x !== stepConfig.xEnd && x >= 0 && x < GRID_WIDTH; x += stepConfig.xStep) {
      const index = y * GRID_WIDTH + x;
      const elementBehavior = ELEMENT_BEHAVIORS[inputGrid[index]];
      elementBehavior?.onStep(index, inputGrid, newGrid);
    }
  }

  for (let y = GRID_HEIGHT - 1; y >= 1; y--) {
    for (let x = 0; x < GRID_WIDTH; x++) {
      const index = y * GRID_WIDTH + x;
      const aboveIndex = (y - 1) * GRID_WIDTH + x;
      if (inputGrid[index] === EMPTY && inputGrid[aboveIndex] === SAND) {
        newGrid[index] = SAND;
        newGrid[aboveIndex] = EMPTY;
      }
    }
  }

  return newGrid;
}

interface StepConfig {
  yStart: number;
  yEnd: number;
  yStep: number;
  xStart: number;
  xEnd: number;
  xStep: number;
}

function getStepConfig(localGridStep: number): StepConfig {
  const stepMod4 = localGridStep % 4;
  const yStart = stepMod4 === 0 || stepMod4 === 3 ? 0 : GRID_HEIGHT - 1;
  const yEnd = stepMod4 === 0 || stepMod4 === 3 ? GRID_HEIGHT : -1;
  const yStep = stepMod4 === 0 || stepMod4 === 3 ? 1 : -1;
  const xStart = stepMod4 === 0 || stepMod4 === 1 ? 0 : GRID_WIDTH - 1;
  const xEnd = stepMod4 === 0 || stepMod4 === 1 ? GRID_WIDTH : -1;
  const xStep = stepMod4 === 0 || stepMod4 === 1 ? 1 : -1;
  return { yStart, yEnd, yStep, xStart, xEnd, xStep };
}





export function getGridStep() {
  return localGridStep;
}

export const SERVER_GRID_UPDATE_FREQUENCY = 15;
export const PREDICT_MULTIPLE = 8;

export enum MouseActionType {
  CLICK = 'click',
  DRAG = 'drag',
  RELEASE = 'release',
}

export interface DragMouseActionMessage extends BrushActionMessage {
  actionType: MouseActionType.DRAG;
  lastX: number;
  lastY: number;
}

export interface BrushActionMessage extends MouseActionMessage {
  actionType: MouseActionType.DRAG;
  frame: number;
}

export interface ClickActionMessage extends MouseActionMessage {
  actionType: MouseActionType.CLICK;
  frame: number;
}

export interface MouseActionMessage {
  actionType: MouseActionType;
  x: number;
  y: number;
  cellType: CellType;
  brushSize: number;
}

export function drawLine(grid: Grid1D, x1: number, y1: number, x2: number, y2: number, radius: number, cellType: CellType) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const steps = Math.max(Math.abs(dx), Math.abs(dy));
  const deltaX = dx / steps;
  const deltaY = dy / steps;
  let x = x1, y = y1;

  for (let i = 0; i <= steps; i++) {
    drawCircle(grid, Math.round(x), Math.round(y), radius, cellType);
    x += deltaX;
    y += deltaY;
  }
}

export function drawCircle(grid: Grid1D, cx: number, cy: number, radius: number, cellType: CellType) {
  if (radius === 1) {
    grid[cy * GRID_WIDTH + cx] = cellType;
    return;
  }

  for (let y = Math.max(0, cy - radius); y <= Math.min(GRID_HEIGHT - 1, cy + radius); y++) {
    for (let x = Math.max(0, cx - radius); x <= Math.min(GRID_WIDTH - 1, cx + radius); x++) {
      const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
      if (distance <= radius) {
        const index = y * GRID_WIDTH + x;
        if (grid[index] === OBSTACLE && cellType === SAND) {
          continue;
        }
        grid[index] = cellType;
      }
    }
  }
}
